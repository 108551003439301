import React, { useRef, useEffect } from 'react';
import styles from './Error.module.scss';

type Props = {
  title?: string,
  children: React.Node
};

const Page = ({ title, children }: Props) => {
  const pageRef = useRef();

  useEffect(() => {
    pageRef.current.scrollIntoView();
  });

  return (
    <div ref={pageRef} className={styles["error-page"]}>
      <div className={styles["error-page__inner"]}>
        <div className={styles["error-page__body"]}>
          <img src="/media/error-illustration.svg" width="35%" />

          <h1 className={styles["error-page__title"]}>Page not found</h1>

          <p>Oops... It seems the page you are looking for is no longer there.</p>
        </div>
      </div>
    </div>
  );
};

export default Page;