// @flow strict
import React from 'react';
import Sidebar from '../components/Sidebar';
import Layout from '../components/Layout';
import Error from '../components/Error';
import { useSiteMetadata } from '../hooks';
import Copyright from '../components/Copyright';

const NotFoundTemplate = () => {
  const { title, subtitle, copyright } = useSiteMetadata();

  return (
    <>
      <Layout title={`Not Found - ${title}`} description={subtitle}>
        <Sidebar />
        <Error></Error>
      </Layout>
      <Copyright copyright={copyright} />
    </>
  );
};

export default NotFoundTemplate;
